import React, { useContext } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { isFuture } from 'date-fns';
import { useRouter } from 'next/router';
import useFlags from '@lib/hooks/useFlags';
import messageStripe from '@components/MessageStripe';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import { isEnabledOnPage } from '@lib/utils';
import { SubscriptionStatus } from '@gql/generated';

const MessageStripe = styled(messageStripe)`
  position: sticky;
  top: 0;
`;

const TEXTS = {
  first: {
    se:
      '💙 Specialkampanj 💙 5 superexklusiva LIVE-event + 6 månader access till Yogobe Play. Klicka här!',
  },
  last: {
    se:
      'SISTA CHANSEN 🔥 Säkra din plats på höstens superexklusiva LIVE-event + få 6 månader Yogobe Play. Du sparar 1373 SEK.',
  },
};

const start_date = new Date(Date.UTC(2024, 7, 22, 23));
const end_date = new Date(Date.UTC(2024, 8, 30, 23));

const KampanjBanner = () => {
  const router = useRouter();
  const [flags] = useFlags();
  const lang = useContext(LanguageContext);
  const [currentUser] = useContext(UserContext);

  const ENABLED_PATHS = [
    { path: '/[lang]', exact: true },
    { path: '/[lang]/courses', exact: true },
    { path: '/[lang]/programs', exact: true },
    { path: '/[lang]/videos', exact: false },
  ];

  const allowed_on_page =
    ['se', 'no'].includes(lang) &&
    isEnabledOnPage(ENABLED_PATHS, router.pathname);

  if (
    !allowed_on_page ||
    !flags?.six_months_campaign ||
    isFuture(start_date) ||
    !isFuture(end_date)
  ) {
    return null;
  }

  const text = isFuture(new Date(Date.UTC(2024, 8, 22, 23)))
    ? TEXTS['first'].se
    : TEXTS['last'].se;

  const bgColor = '#D9E8E7';
  const color = '#242424';

  return (
    <MessageStripe bg={bgColor} color={color}>
      <Link href={`/${lang}/kampanj`}>{text}</Link>
    </MessageStripe>
  );
};

export default KampanjBanner;
